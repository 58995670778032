<template>
  <div>
    <div class="p-grid">
      <div class="p-col">
        <h3><i class="pi pi-file" style="font-size: 1.6rem"></i> Capitalização</h3>
      </div>
      <div class="p-col p-text-right">
        <Button v-if="$auth.userCan('CAPITALIZACAO_CADASTRAR')" label="novo título" icon="pi pi-plus" class="p-button p-button-secondary"  @click="$router.push({ path: `/capitalizacao` })" />
      </div>
    </div>

    <div class="p-grid">
      <div class="p-col">
        <AutoComplete v-model="filtrosSelecionados" :multiple="true" :suggestions="filtrosResultados" @complete="filtrarAutoComplete($event)" 
        field="completeLabel" optionGroupLabel="label"  :completeOnFocus="true" optionGroupChildren="items" placeholder="Filtre seus resultados" style="display: block">
          <template #item="slotProps">
            <div>{{slotProps.item.label}}</div>
          </template>
        </AutoComplete>
      </div>
    </div>

    <DataTable sortMode="single" sortField="data" :sortOrder="-1" :value="capitalizacoes" :loading="loading" selectionMode="single" v-model:selection="capitalizacao">
      <Column :sortable="true" field="id" header="Negócio" class="col-reduzida"></Column>
      <Column :sortable="true" field="imovel_item.id_proprio" header="Imóvel" class="col-reduzida">
        <template #body="slotProps">
          <span v-if="slotProps.data.imovel_item.id_proprio"> {{ slotProps.data.imovel_item.id_proprio }}</span>
          <span v-else>IMÓVEL {{ slotProps.data.imovel }}</span>
        </template>
      </Column>
      <Column :sortable="true" field="locatario_item.nome" header="Locatário"></Column>
      <Column :sortable="true" field="data" header="Data Cadastro">
        <template #body="slotProps">
          {{ $utils.formatDateTimeToBr(slotProps.data.data) }}
        </template>
      </Column>
      <Column :sortable="true" field="data_atualizacao" header="Data Atualização">
        <template #body="slotProps">
          {{ $utils.formatDateTimeToBr(slotProps.data.data_atualizacao) }}
        </template>
      </Column>
      <Column :sortable="true" field="status" header="Status">
        <template #body="slotProps">
          <Tag 
            :value="status[slotProps.data.status].label"
            :icon="status[slotProps.data.status].icon" 
            :severity="status[slotProps.data.status].severity"
            :class="(typeof status[slotProps.data.status].class != 'undefined')?status[slotProps.data.status].class:''"
          >
          </Tag>
        </template>
      </Column>
      <!-- <Column class="col-actions">
        <template #body="slotProps">
            <Button icon="pi pi-clone" v-tooltip.left="'Clonar Análise'" v-if="$auth.userCan('CAPITALIZACAO_CADASTRAR')" class="p-button-rounded p-button-warning p-mr-2" @click="$router.push({ path: `/processo/clonar/${slotProps.data.id}` })" />
            <Button icon="pi pi-trash" v-tooltip.top="'Arquivar Análise'" v-if="$auth.userCan('CAPITALIZACAO_EXCLUIR') && slotProps.data.status != 'CONCLUIDO' && slotProps.data.status != 'NEGADO'" class="p-button-rounded p-button-danger" @click="excluir($event, slotProps.data)" />
        </template>
      </Column> -->
      <template #empty>
          Nenhum registro encontrado.
      </template>
    </DataTable>

  </div>

  <ConfirmPopup></ConfirmPopup>
  <Toast position="top-right" />

</template>

<script>

export default {
  
  data() {
    return {

      // AUTOCOMPLETE OPCIONAL: //
      filtrosPersonalizados: 'Negócio/Locatário',
      // AUTOCOMPLETE OBRIGATORIOS: //
      filtrosSelecionados: null,
      filtrosResultados: [],
      filtrosOpcoes: [],
      // FIM AUTOCOMPLETE //

      loading: true,
      api_get: '?relationships=imovel_item:id_proprio;locatario_item:nome',

      capitalizacoes: [],
      capitalizacao: null,

      status: {
        
        'CADASTRO_ENVIADO' : {
          label: 'CADASTRO ENVIADO',
          severity: "info",
          icon: "pi pi-clock",
          class: 'tag-novo',
        },
        'AGUARDANDO_PAGAMENTO' : {
          label: 'AGUARDANDO PAGAMENTO',
          severity: "warning",
          icon: "pi pi-exclamation-triangle",
          class: 'tag-pagamento'
        },
        'TITULO_EMITIDO': {
          label: 'TÍTULO EMITIDO',
          severity: "info",
          icon: "pi pi-check",
        },
        'RECUSADO' : {
          label: 'RECUSADO',
          severity: "danger",
          icon: "pi pi-times",
        },
        'TITULO_CANCELADO' : {
          label: 'TÍTULO CANCELADO',
          severity: "danger",
          icon: "pi pi-times",
        },

      },

    };
  },

  mounted() {

    const self = this;

    this.$api.get('/capitalizacao/filtros').then(function (response) {

      const dados = response.data;

      if(dados.success) {

        self.filtrosOpcoes = dados.data;
 
      }
      
    });

    this.$api.get('/capitalizacao' + this.api_get).then(function (response) {

      const dados = response.data;

      if(dados.success) {
        self.capitalizacoes = dados.data;
      }

      self.loading = false;
      
    });

  },

  watch: {

    capitalizacao(val) {

      if(val) {
        this.$router.push({ path: `/capitalizacao/${val.id}` });
      }

    },

    filtrosSelecionados(v) {

      const self = this;
      var filtrosQuery = "";

      self.loading = true;

      if(Array.isArray(v) && v.length > 0) {
        var p = {};
        this.filtrosSelecionados.forEach((f) => {
          if(typeof p[f.group] == "undefined")
            p[f.group] = [];
          p[f.group].push(f.id);
        });
        filtrosQuery = JSON.stringify(p); //TODO PASSAR PARA A URL PARA QUE QUANDO A PESSOA ATUALIZAR A TELA OS FILTROS PERMANEÇAM
      }

      this.$api.get('/capitalizacao'  + this.api_get + '&filters=' + filtrosQuery).then(function (response) {

        const dados = response.data;

        if(dados.success) {
          self.capitalizacoes = dados.data;
        }

        self.loading = false;
        
      });


    },

  },

  methods: {

    filtrarAutoComplete: function($e) {

      const self = this;

      const q = $e.query.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();

      var ids_selecionados = [];
      if(self.filtrosSelecionados != null) {
        self.filtrosSelecionados.forEach((i) => {
          ids_selecionados.push(i.id);
        });
      }

      self.filtrosResultados = [];
      for (const _g of Object.values(self.filtrosOpcoes)) {
        const g = Object.assign({}, _g);
        var el = [];
        for (const _e of Object.values(g.items)) {
          const e = Object.assign({}, _e);
          if(e.label.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").indexOf(q) >= 0) {
            if(ids_selecionados.indexOf(e.id) == -1) {
              e.completeLabel = g.label + ': ' + e.label; // Mudança na apresentação do dado selecionado
              el.push(e);
            }
          }
        }
        if(el.length > 0) {
          g.items = el;
          self.filtrosResultados.push(g);
        }
      }

      if(typeof self.filtrosPersonalizados == 'string') {

        self.filtrosResultados.push({
          label: self.filtrosPersonalizados, id: 'str', 
          items: [
            {label: $e.query, id: $e.query, group: 'str', completeLabel: self.filtrosPersonalizados + ': ' + $e.query}
          ]
        });

      }

    },

  }
};

</script>

<style scoped>

  :deep(.col-reduzida) {
    width: 120px;
  }

  :deep(.p-tag.tag-novo) {
    background-color: #ff3399;
  }

  :deep(.p-tag.tag-pagamento) {
    background-color: #ff8133;
    color: white;
  }

</style>